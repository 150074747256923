import { fetch } from "whatwg-fetch";

const environment = process.env.NODE_ENV;

export default function fetch_json_action_creator(
  fetch_action,
  method = "GET",
  data
) {
  // POST method not working in dev environment. So, navigating all request to GET only for dev
  if (environment === "development") {
    method = "GET";
    data = null;
  }

  // To form URL params for GET method
  let url = fetch_action;

  if (method === "GET" && data) {
    let params = Object.keys(data)
      .map(function (key) {
        return key + "=" + data[key];
      })
      .join("&");
    url = url + "?" + params;

    data = null;
  }

  let csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  // Validate the method to address SAST issue
  const allowedMethods = ["GET", "POST", "PUT", "DELETE"];
  function validateMethod(method) {
    if (!allowedMethods.includes(method.toUpperCase())) {
      console.error("Invalid HTTP method");
      throw new Error("Invalid HTTP method");
    }
  }

  function validateUrl(url, environment) {

    // Ensure the URL is a relative path (not absolute URL like http:// or https://)
    if (url.startsWith("http://") || url.startsWith("https://")) {
      console.error("Absolute URLs are not allowed");
      throw new Error("Absolute URLs are not allowed");
    }


    // If in development mode, allow localhost/127.0.0.1 for local testing
    if (environment === "development") {
      return;
    }

    // In production or other environments, ensure no internal addresses are used
    const forbiddenPatterns = [
      "localhost",
      "127.0.0.1",
      "::1", // IPv6 localhost
      "0.0.0.0", // Loopback address
    ];

    for (const pattern of forbiddenPatterns) {
      if (url.includes(pattern)) {
        console.error("Access to internal resources is not allowed");
        throw new Error(
          "Access to internal resources is not allowed"
        );
      }
    }

    // check for URL doesn't have dangerous paths
    const forbiddenPaths = ["admin", "config", "debug"];
    for (const path of forbiddenPaths) {
      if (url.includes(path)) {
        console.error("Access to restricted paths is not allowed");
        throw new Error("Access to restricted paths is not allowed");
      }
    }

  }

  validateMethod(method);
  validateUrl(url, environment);

  return fetch(url, {
    method, // *GET, POST, PUT, DELETE, etc.
    mode: environment === "development" ? "no-cors" : "same-origin", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, same-origin, *omit
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-TOKEN": csrfToken,
    },
    redirect: "follow", // manual, *follow, error
    referrer: "no-referrer", // no-referrer, *client
    body: data && JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        console.log("Server Error: " + res.status + " - " + res.statusText);
      }
    })
    .then((res) => {
      return new Promise((resolve) => resolve(res));
    })
    .catch(function (ex) {
      console.log("parsing failed: ", ex);
    });
}
